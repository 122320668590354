import {
  bootstrapCameraKit,
  CameraKitSession,
  createMediaStreamSource,
  Transform2D
} from '@snap/camera-kit';

import React, { useEffect, useRef, useState } from 'react';

import './styles.css';
import MobileIntro from './MobileIntro';
import RecordButton from './RecordButton';
import VideoPlayer from './VideoPlayer';
import ShareButton from './ShareButton';
import { IconButton } from './IconButton';
import { PoweredBySnapchat } from './PoweredBySnapchat';

import { ga_event } from './Analytics';
import { useDeviceOrientation } from './hooks/useDeviceOrientation';
import LandscapeFallback from './LandscapeFallback';

import { useMediaRecorder, type CameraType } from './hooks/useMediaRecorder';
import { SlideUpDrawer } from './SlideUpDrawer';

import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL,fetchFile } from '@ffmpeg/util';


const Snap: React.FC = () => {
  const { isPortrait } = useDeviceOrientation();
  const canvasRef = useRef<HTMLCanvasElement>(null!);
  const sessionRef = useRef<CameraKitSession | null>(null);
  const streamRef = useRef<MediaStream>(null!);

  const ffmpegRef = useRef(new FFmpeg());
  const [loaded, setLoaded] = useState(false);
  const messageRef = useRef<HTMLParagraphElement | null>(null)

  const [disableInstructions, setDisableInstructions] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  /** for preventing double initialization call in development due to react.strict mode useEffect double call on mount */
  const isInitializing = useRef(false);

  /**
   * @note managing camera selected 2 ways, bc I could not simplify to use only state quickly.
   *  - isBackFacingRef -> used in updateCamera to update with the latest value
   *  - setting selectedCamera state to provide useMediaRecorder with selected camera, since refs do not cause re-renders
   * @todo completely replace isBackFacingRef with selectedCamera state to manage the camera. will require `updateCamera` to be refactored.
   */
  const isBackFacingRef = useRef(false);
  const [selectedCamera, setSelectedCamera] = useState<CameraType>(
    isBackFacingRef.current ? 'back' : 'front'
  );
  // const debugRef = useRef<String>('');

  const mediaRecorder = useMediaRecorder({
    canvasRef,
    camera: selectedCamera
  });

  const load = async () => {
    const baseURL = "https://kiki.thefabricant.com";
    const ffmpeg = ffmpegRef.current;
    ffmpeg.on("log", ({ message }) => {
      if (messageRef.current) messageRef.current.innerHTML = message;
    });
    // toBlobURL is used to bypass CORS issue, urls with the same
    // domain can be used directly.
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
      wasmURL: await toBlobURL(
        `${baseURL}/ffmpeg-core.wasm`,
        "application/wasm"
      ),
      workerURL: await toBlobURL(
        `${baseURL}/ffmpeg-core.worker.js`,
        "text/javascript"
      ),
    });
    setLoaded(true);
  };

  const updateCamera = async () => {
    if (!sessionRef.current || !canvasRef.current) return;

    if (streamRef.current) {
      sessionRef.current.pause();
      streamRef.current.getVideoTracks()[0].stop();
    }

    /** set here to force useMediaRecorder to re-initialize with correct camera */
    setSelectedCamera(isBackFacingRef.current ? 'back' : 'front');

    streamRef.current = await navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: isBackFacingRef.current ? 'environment' : 'user'
      },
      audio: true
    });

    const source = createMediaStreamSource(streamRef.current, {
      // NOTE: This is important for world facing experiences
      cameraType: isBackFacingRef.current ? 'back' : 'front'
    });

    await sessionRef.current.setSource(source);

    await source.setRenderSize(
      canvasRef.current?.offsetWidth * 2,
      canvasRef.current?.offsetHeight * 2
    );

    if (!isBackFacingRef.current) {
      source.setTransform(Transform2D.MirrorX);
    }

    sessionRef.current.play('live');
  };

  const toggleBackCamera = () => {
    isBackFacingRef.current = !isBackFacingRef.current;
    updateCamera();
  };

  const clearRecordedMedia = React.useCallback(() => {
    mediaRecorder.clearRecording();
  }, [mediaRecorder]);

  const clickShareButton = async () => {
    const { recording } = mediaRecorder;
    if (!recording) {
      console.warn('oops, no recording to share');
      return;
    }

    const fileFormatMap: Record<typeof recording.type, string> = {
      image: 'png',
      video: 'mp4'
    };

    const fileFormat = fileFormatMap[recording.type];

    const ffmpeg = ffmpegRef.current;

    const blob: Blob = recording.blob;

    console.log("I");


    async function makeBlobSeekable(_blob:Blob) {
      await ffmpeg.load();
      
      const originalFileName = 'recording.mp4'; // Use appropriate file name and extension
      ffmpeg.writeFile(originalFileName, await fetchFile(_blob));
    
      // Re-encode the video. This example command just copies the existing streams.
      // Adjust ffmpeg command as needed for your specific case.
      await ffmpeg.exec(["-i", originalFileName, 'seekable.mp4']);
    
      const fileData = await ffmpeg.readFile('seekable.mp4');
      const data = new Uint8Array(fileData as ArrayBuffer);
      return new Blob([data.buffer], { type: 'video/mp4' });
    }
    
    // Usage
    const seekableBlob = await makeBlobSeekable(blob);


    console.log("II");
    // const url = URL.createObjectURL(seekableBlob);

    // console.log(url);
    



    
    const file = new File([seekableBlob], `KikiWorld.${fileFormat}`, {
      type: `${recording.type}/${fileFormat}`,
      lastModified: new Date().getTime()
    });

    const cameraType = isBackFacingRef ? 'back' : 'front';

        // Create a URL for the file
        const url = URL.createObjectURL(file);

        // Create a temporary download link
        const a = document.createElement('a');
        a.href = url;
        a.download = `KikiWorld.${fileFormat}`;
        document.body.appendChild(a);
    
        // Programmatically click the link to trigger the download
        a.click();
    
        // Clean up: remove the link and revoke the object URL
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    
        // Clear recorded media or any other post-download actions
        clearRecordedMedia();

    // if (navigator.canShare && navigator.canShare({ files: [file] })) {
    //   navigator
    //     .share({
    //       files: [file] //or using arrays refer to the end comment
    //       // title: 'The Fabricant Drip Glasses',
    //       // text: 'The Fabricant Drip Glasses',
    //     })
    //     .then(() => {
    //       if (process.env.REACT_APP_ENV === 'development')
    //       {
    //         console.log("debugging share event");
    //       }
    //       else
    //       {
    //         ga_event(`shared-${recording.type}-${cameraType}`, {});
    //       }

    //       clearRecordedMedia();
    //     })
    //     .catch((error) => console.log('Sharing failed', error));
    // } else {
    //   console.warn(`Your system doesn't support sharing files.`);
    // }
  };

  const handleRecording = (isRecording: boolean) => {
    /** we can assume user knows how to use record button now.
     * disable instructions
     */
    if (!disableInstructions) {
      setDisableInstructions(true);
    }

    if (isRecording) {
      mediaRecorder.start();
    } else {
      mediaRecorder.stop();
    }
  };

  const handleShareAppLink = () => {
    /**
     * use the hostname and add new campaign
     */
    // const url = `${window.location.hostname}?campaign=share`;
    const url = `?campaign=share`;

    if (navigator.canShare && navigator.canShare({ url })) {
      navigator
        .share({
          url,
          title: 'The Fabricant | Dress the Beat'
        })
        .then(() => {
          if (process.env.REACT_APP_ENV === 'development')
          {
            console.log("debugging shared url");
          }
          else
          {
            ga_event(`shared-url`, {});
          }
        })
        .catch((error) => console.log('Sharing failed', error));
    } else {
      console.warn(`Your system doesn't support sharing urls.`);
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      console.warn('tried to initialize camerakit,but no canvas');
      return;
    }


    const initializeCameraKit = async () => {
      isInitializing.current = true;
      console.log('initializeCameraKit');

      try {

        // start with FFMPEG actually
        await load();

        const cameraKit = await bootstrapCameraKit({
          apiToken: process.env.REACT_APP_SNAP_CAMERA_KIT_TOKEN!
          // logger: 'console'
        });

        const session = await cameraKit.createSession({
          liveRenderTarget: canvas
        });

        const lens = await cameraKit.lensRepository.loadLens(
          '245b4210-3e52-415c-af65-0b4bd18a98ff',
          'a91f2abd-ed64-4105-bcef-a523b816ecf8'
        );
        await session.applyLens(lens, { showLogo: "false"});
        sessionRef.current = session;
        await updateCamera();
        setIsInitialized(true);
      } catch (error: unknown) {
        console.error(error);
        return null;
      } finally {
        isInitializing.current = false;
      }
    };

    if (isInitializing.current === false) {
      /** prevent double mount useEffect error in development from trying to initialize 2x */
      initializeCameraKit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isInitializing.current) {
      updateCamera();
    }
  }, [isPortrait]);

  const menuContent = (
    <>
      {!mediaRecorder.recording && (
        <div className="grid grid-cols-[1fr,2fr,1fr] gap-6 items-center justify-items-center px-10 py-4 sticky bottom-0 ">
          <IconButton
            disabled={mediaRecorder.isRecording}
            icon="sync"
            onClick={toggleBackCamera}
          />
          <RecordButton
            disableInstructions={disableInstructions}
            toggleRecording={handleRecording}
          />
          <IconButton icon="share" onClick={handleShareAppLink} />
        </div>
      )}

      {!!mediaRecorder.recording && (
        <div className="absolute h-full w-full">
          <SlideUpDrawer isPhoto={mediaRecorder.recording.type === 'image'}>
            {mediaRecorder.recording.type === 'image' && (
              <img
                className="h-full w-full"
                height={canvasRef.current?.offsetWidth}
                width={canvasRef.current?.offsetHeight}
                src={URL.createObjectURL(mediaRecorder.recording.blob)}
                alt="Drip Glasses"
              />
            )}
            {mediaRecorder.recording.type === 'video' && (
              <VideoPlayer videoBlob={mediaRecorder.recording.blob} />
            )}
            <ShareButton
              title={mediaRecorder.recording?.type}
              clickShareButton={clickShareButton}
            />
            <div className="absolute left-5 top-5">
              <IconButton icon="back" onClick={clearRecordedMedia} />
            </div>
          </SlideUpDrawer>
        </div>
      )}
    </>
  );

  return (
    <div className="fixed inset-0 h-full w-full grid">
      <div className="relative h-full w-full">
        {/* <p>{debugRef.current}</p> */}
        <canvas
          id="live-canvas"
          className="absolute inset-0 h-full w-full touch-none z-0 overflow-hidden"
          ref={canvasRef}
        />

        {!isPortrait && (
          <div className="fixed inset-0 bg-black/80">
            <LandscapeFallback instructions="Landscape mode is not supported, yet. " />
          </div>
        )}

        {isPortrait && isInitialized && (
          <>
            <div className="flex flex-col justify-between h-full w-full fixed inset-0">
              <div />
              {menuContent}
            </div>
          </>
        )}

        {isPortrait && !isInitialized && (
          <MobileIntro
            title="WEAR TO WIN"
            instructions={
              <>
                Wear and share our filter for a chance to win the entire KIKI Collection!
                <br />
                Follow and Tag 
                <a 
                  href="https://www.instagram.com/kikiworld___" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-800"
                >
                  &nbsp;@kikiworld___
                </a>&nbsp;and
                <a 
                  href="https://www.instagram.com/the_fab_ric_ant" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-800"
                >
                  &nbsp;@thefabricant
                </a>
                &nbsp;before Jan 1!
                <br />
                <br />
                <a 
                  href="https://www.thefabricant.com" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-800 text-sm"
                >
                  Terms and Conditions apply
                </a>
              </>
            }
          />
        )}
      </div>
      {isInitialized && !mediaRecorder.recording && <PoweredBySnapchat />}
    </div>
  );
};

export default Snap;
