import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';

export function useSentry() {
  const sentryInitialized = useRef(false);
  useEffect(() => {
    if (sentryInitialized.current === false) {
      Sentry.init({
        dsn: 'https://b0bb40ac334bcfd7e56b3d60a9e7c301@o1021155.ingest.sentry.io/4506389872705536',
        integrations: [
          new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost']
          })
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0 // Capture 100% of the transactions
      });
      sentryInitialized.current = true;
    }
  }, []);
}
