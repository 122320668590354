// MobileIntro.tsx

import React from 'react';
import './styles.css';
import MobileIntroLogos from './MobileIntroLogos';

import { FallbackContainer } from './FallbackContainer';

interface MobileIntroProps {
  title: string;
  instructions: React.ReactNode;
}

const MobileIntro: React.FC<MobileIntroProps> = ({ title, instructions }) => {
  return (
    <FallbackContainer isMobile>
      <h1 className="font-serif text-[3rem] text-white italic">{title}</h1>
      <p className="text-[22px] text-white text-center font-light tracking-tighter text-sm">
        {instructions}
      </p>
      <MobileIntroLogos />
    </FallbackContainer>
  );
};

export default MobileIntro;
