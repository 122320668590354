// TypeScript definition for gtag
declare global {
    interface Window {
      gtag: (...args: any[]) => void;
    }
  }
  
  export const ga_pageview = (url: string) => {
    window.gtag('config', 'G-NXNNP565VR', {
      page_path: url,
    });
  };
  
  export const ga_event = (action: string, params: {}) => {
    window.gtag('event', action, params);
  };
  