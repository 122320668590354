import * as React from 'react';
import Snap from './Snap';
import { isMobile } from 'react-device-detect';
import DesktopFallback from './DesktopFallback';
import { useSentry } from './third-party-services/useSentry';
import { ga_event } from './Analytics';

function reportCampaign() {
  // getting the campaing parameter
  // Parse the query parameters from the URL
  const params = new URLSearchParams(window.location.search);

  const campaign = params.get('utm_campaign');

  if (campaign) {

    if (process.env.REACT_APP_ENV === 'development')
    {
      console.log("debugging campaign event");
    }
    else
    {
      ga_event(`campaign-${campaign}`, {});
    }
    
  }
}

const App: React.FC = () => {
  useSentry();
  React.useEffect(() => {
    reportCampaign();
  }, []);

  return (
    <div className="relative h-[100dvh] w-full bg-black">
      {!isMobile && (
        <DesktopFallback
          title="WEAR TO WIN"
          instructions={
            <>
              Wear and share our filter for a chance to win the entire KIKI Collection!
              <br />
              Follow and Tag 
              <a 
                href="https://www.instagram.com/kikiworld___" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-800"
              >
                &nbsp;@kikiworld___
              </a>&nbsp;and
              <a 
                href="https://www.instagram.com/the_fab_ric_ant" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-800"
              >
                &nbsp;@thefabricant
              </a>
              &nbsp;before Jan 1!
              <br />
              <br />
              <a 
                href="https://www.thefabricant.com" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-800 text-sm"
              >
                Terms and Conditions apply
              </a>
            </>
          }
        />
      )}
      {isMobile && <Snap />}
    </div>
  );
};

export default App;
