import * as React from 'react';
import { isMobile } from 'react-device-detect';
import useResizeObserver from 'use-resize-observer';

const sources: {
  desktop: Record<string, string>;
  mobile: Record<string, string>;
} = {
  desktop: {
    720: 'https://player.vimeo.com/progressive_redirect/playback/894706066/rendition/720p/file.mp4?loc=external&log_user=0&signature=cb48dd3200e14d3200f60abde47f5d2fbe7979dc44b9981280fcae5bb82f9402',
    540: 'https://player.vimeo.com/progressive_redirect/playback/894706066/rendition/540p/file.mp4?loc=external&log_user=0&signature=ecb73bb178bd8c0edf072cfbf88bad2ca99708dd07289d3ac71fb160d0ee4219'
  },
  mobile: {
    1080: 'https://player.vimeo.com/progressive_redirect/playback/894706090/rendition/1080p/file.mp4?loc=external&log_user=0&signature=641360c309c539a38dbe76f482a5d66f25d0c126a9afcc85e9dff5b2dcb1a9ba',
    720: 'https://player.vimeo.com/progressive_redirect/playback/894706090/rendition/720p/file.mp4?loc=external&log_user=0&signature=aa8aca422964ca99a5851675ae0ac3b290ca36a638cc9a0c258af69a2b5487ea',
    540: 'https://player.vimeo.com/progressive_redirect/playback/894706090/rendition/540p/file.mp4?loc=external&log_user=0&signature=9ce4d32aad1123ee9f05cbf69ebe143fe5a0ecc2e3332002488c948fb9c05f28',
    360: 'https://player.vimeo.com/progressive_redirect/playback/894706090/rendition/360p/file.mp4?loc=external&log_user=0&signature=16d1eddf1fa1f240751be3bcba1a9a254a12a8d85ed08aef096aa545b5f93513'
  }
};

const posterImg = isMobile
  ? 'https://tf-web-assets.imgix.net/images/kiki/mobile-poster.png?auto=format'
  : 'https://tf-web-assets.imgix.net/images/kiki/desktop-poster.png?auto=format';

export function VideoBg() {
  const { ref, width = 1 } = useResizeObserver<HTMLDivElement>();
  const [isMetadataLoaded, setIsMetadataLoaded] = React.useState(false);

  const handleLoadedMetadata = (
    e: React.SyntheticEvent<HTMLVideoElement, Event>
  ) => {
    setIsMetadataLoaded(true);
    const target: HTMLVideoElement = e.currentTarget;
    target.play();
  };

  const screenSources = isMobile ? sources.mobile : sources.desktop;

  // check the screen size and set the best video src size for best user experience
  // grabbed from VideoLoop - studio-octopus
  const sourceEntries = React.useMemo(() => {
    const entries = Object.entries(screenSources)
      .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
      .map((e, i) => [parseInt(e[0]), e[1]]);
    entries[entries.length - 1][0] = Infinity;
    return entries;
  }, [screenSources]);

  // grabbed from VideoLoop - studio-octopus
  const selectedSource = React.useMemo(() => {
    const bestSizeOption = sourceEntries.filter((entry) => {
      return width < entry[0];
    });
    return bestSizeOption[0][1];
  }, [sourceEntries, width]);

  return (
    <div
      ref={ref}
      className="absolute inset-0 h-full w-full bg-black opacity-30"
    >
      <video
        className="h-full w-full transition-opacity duration-500 ease-out-quart object-cover object-center"
        onLoadedMetadata={handleLoadedMetadata}
        poster={posterImg}
        preload="metadata"
        src={selectedSource as string}
        style={{ opacity: isMetadataLoaded ? 1 : 0 }}
        loop
        muted
        playsInline
      />
    </div>
  );
}
